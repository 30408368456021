export const upperBreakPointWidthPercent:number = 0.3;
export const middleBreakPointWidthPercent:number = 0.333;
export const underBreakPointWidth:number = 160;

export const underBreakPointTopMargin:number = 40;
export const underBreakPointBetweenMargin:number = 80;


export const firstBorderWidth:number = 7;
export const secondBorderWidth:number = 5;
