import {
    TYPE_CHANGE_BACKGROUND_SQUARE_PROPS,
    TYPE_ADD_BACKGROUND_SQUARE_PROPS,
    TYPE_CHANGE_ABOUT_ME_PROPS,
    TYPE_CHANGE_CREATED_PROPS,
    TYPE_CHANGE_TOOLS_PROPS,
    TYPE_CHANGE_CONTACT_PROPS,
    TYPE_CHANGE_BLOG_PROPS,
    TYPE_CHANGE_ABOUT_ME_CONTENT_PROPS,
    TYPE_CHANGE_CREATED_CONTENT_PROPS,
    TYPE_CHANGE_TOOLS_CONTENT_PROPS,
    TYPE_CHANGE_CONTACT_CONTENT_PROPS,
    TYPE_CHANGE_BLOG_CONTENT_PROPS
} from "../../types/redux/action-types";

export const CHANGE_BACKGROUND_SQUARE_PROPS: TYPE_CHANGE_BACKGROUND_SQUARE_PROPS = "CHANGE_BACKGROUND_SQUARE_PROPS";
export const ADD_BACKGROUND_SQUARE_PROPS: TYPE_ADD_BACKGROUND_SQUARE_PROPS = "ADD_BACKGROUND_SQUARE_PROPS";

export const CHANGE_ABOUT_ME_PROPS: TYPE_CHANGE_ABOUT_ME_PROPS = "CHANGE_ABOUT_ME_PROPS";
export const CHANGE_CREATED_PROPS: TYPE_CHANGE_CREATED_PROPS = "CHANGE_CREATED_PROPS";
export const CHANGE_TOOLS_PROPS: TYPE_CHANGE_TOOLS_PROPS = "CHANGE_TOOLS_PROPS";
export const CHANGE_CONTACT_PROPS: TYPE_CHANGE_CONTACT_PROPS = "CHANGE_CONTACT_PROPS";
export const CHANGE_BLOG_PROPS: TYPE_CHANGE_BLOG_PROPS = "CHANGE_BLOG_PROPS";

export const CHANGE_ABOUT_ME_CONTENT_PROPS: TYPE_CHANGE_ABOUT_ME_CONTENT_PROPS = "CHANGE_ABOUT_ME_CONTENT_PROPS";
export const CHANGE_CREATED_CONTENT_PROPS: TYPE_CHANGE_CREATED_CONTENT_PROPS ="CHANGE_CREATED_CONTENT_PROPS";
export const CHANGE_TOOLS_CONTENT_PROPS: TYPE_CHANGE_TOOLS_CONTENT_PROPS = "CHANGE_TOOLS_CONTENT_PROPS";
export const CHANGE_CONTACT_CONTENT_PROPS: TYPE_CHANGE_CONTACT_CONTENT_PROPS = "CHANGE_CONTACT_CONTENT_PROPS";
export const CHANGE_BLOG_CONTENT_PROPS: TYPE_CHANGE_BLOG_CONTENT_PROPS = "CHANGE_BLOG_CONTENT_PROPS";
